import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ReCAPTCHA from 'react-google-recaptcha';

import laserDog from '../images/Laser-AssKicking-Dog.gif';

import './captchaContainer.scss';

const skipCaptchaOnLocalhost = true;

const CaptchaContainer = ({ children, hostname }) => {
  // eslint-disable-next-line no-restricted-globals
  const [isTesting] = useState(
    skipCaptchaOnLocalhost && hostname === 'localhost',
  );
  const [notRobot, setNotRobot] = useState(false);

  const onRecaptchaSuccess = (key) => {
    if (key) {
      setNotRobot(true);
    } else {
      setNotRobot(false);
    }
  };

  if (notRobot || isTesting) {
    return children;
  }

  return (
    <div className="captcha-container">
      <img src={laserDog} width="512" alt="Guard Dog" />
      <h1>Prove you're not a robot or cat!</h1>
      <ReCAPTCHA
        sitekey="6LdpmeggAAAAADf2Zr6hVCJw5gY-3aEgUjL5nybb"
        onChange={onRecaptchaSuccess}
      />
    </div>
  );
};

CaptchaContainer.propTypes = {
  children: PropTypes.node.isRequired,
  hostname: PropTypes.string.isRequired,
};

export default CaptchaContainer;
