import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { times } from 'lodash';
import { Select, MenuItem } from '@mui/material';

import PurchaseModal from '../components/purchaseModal';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Text from '../components/text';
import Header from '../components/header';
import Button from '../components/button';
import CaptchaContainer from '../components/captchaContainer';
import laserDog from '../images/Laser-AssKicking-Dog.gif';

import './adopt.scss';

const costPerNft = 3000000;
const costInAda = (costPerNft / 1000000);

const Minting = ({ location }) => {
  const [selectedCount, setSelectedCount] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const changeSelectedCount = (event) => {
    const newCount = event.target.value;
    setSelectedCount(parseInt(newCount, 10));
  };

  return (
    <Layout>
      <Seo title="Chains on Dogs - Minting" />
      <Header />
      <div className="minting">
        <CaptchaContainer hostname={location.hostname}>
          <Text>
            <div className="minting__content">
              <img src={laserDog} width="512" alt="Guard Dog" />
              <h1>Woof Woof Woof!</h1>
              <p>1 Doggo per TX. Max 2 Per Wallet!</p>
              <p>Send exactly 3 ADA.</p>

              <Select
                value={selectedCount}
                onChange={changeSelectedCount}
                sx={{
                  marginRight: 1,
                  root: {
                    backgroundColor: 'white',
                    fontSize: 24,
                    paddingTop: 14,
                    paddingRight: 20,
                    paddingBottom: 5,
                    paddingLeft: 10,
                    fontWeight: 600,
                    width: 160,
                    color: '#11285a',
                    textAlign: 'left',
                    fontFamily: 'Cabin',
                    '&:focus': {
                      backgroundColor: '#f3f6f7',
                    },
                    '&:hover': {
                      backgroundColor: '#f3f6f7',
                    },
                  },
                }}
              >
                {
                times(1, (index) => {
                  const value = index + 1;
                  return (
                    <MenuItem
                      key={value}
                      value={value}
                      sx={{
                        root: {
                          color: '#11285a',
                          fontFamily: 'Cabin',
                          fontWeight: 600,
                        },
                      }}
                    >
                      {`${value}x (${costInAda * value} ADA)`}
                    </MenuItem>
                  );
                })
              }
              </Select>
              <Button
                label="Mint"
                onButtonClick={() => setModalIsOpen(true)}
                size="large"
              />
            </div>
          </Text>
        </CaptchaContainer>
      </div>
      <PurchaseModal
        modalIsOpen={modalIsOpen}
        costInAda={selectedCount * costInAda}
        costInLovelace={selectedCount * costPerNft}
        selectedCount={selectedCount}
        setModalIsOpen={setModalIsOpen}
      />
    </Layout>
  );
};

Minting.propTypes = {
  location: PropTypes.shape({
    hostname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Minting;
