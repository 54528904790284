import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-modal';
import Countdown from 'react-countdown';
import { Snackbar } from '@mui/material';
import { navigate } from '@reach/router';

import { getPurchaseAddressData } from '../api/purchaseApi';
import Button from './button';

import getYour from '../images/get-your.png';
import untHere from '../images/unt-here.png';

import './purchaseModal.scss';

const PurchaseModal = ({
  modalIsOpen,
  setModalIsOpen,
  selectedCount,
  costInAda,
  costInLovelace,
}) => {
  const expiresDate = null;

  const [purchaseAddressData, setPurchaseAddressData] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (modalIsOpen) {
      getPurchaseAddressData(
        selectedCount,
        setPurchaseAddressData,
        costInLovelace,
      );
    }
  }, [modalIsOpen, selectedCount, costInLovelace]);

  const onClickCancel = () => {
    setPurchaseAddressData(null);
    setModalIsOpen(false);
  };

  const onCopyAddress = (address) => {
    setSnackbarOpen(true);
    navigator.clipboard.writeText(address);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      className="purchase-modal"
      overlayClassName="purchase-modal__overlay"
      ariaHideApp={false}
    >
      <div className="purchase-modal__stroke-2">
        <div className="purchase-modal__stroke-1">
          {purchaseAddressData?.address && (
            <div className="purchase-modal__container">
              <button
                className="purchase-modal__cancel"
                type="button"
                onClick={onClickCancel}
              >
                X (Cancel Order)
              </button>
              <div className="purchase-modal__header">
                <h1>Get Your Doggo Here!</h1>
              </div>
              <div className="purchase-modal__content">
                <div className="purchase-modal__details">
                  <div>
                    <h2>{`Quantity: ${selectedCount}`}</h2>
                  </div>
                  <div>
                    <h2>{`Price: ${costInAda} ADA`}</h2>
                  </div>
                </div>
                <h2>How to buy:</h2>
                <div className="purchase-modal__instruction">
                  1. Copy this address
                  {purchaseAddressData.address.substr(0, 9) === 'addr_test' && (
                    <span> (TESTING: Do not send ADA to this address!)</span>
                  )}
                </div>
                <div className="purchase-modal__address">
                  <input
                    type="text"
                    value={purchaseAddressData.address}
                    disabled
                  />

                  <button
                    type="button"
                    onClick={() => onCopyAddress(purchaseAddressData?.address)}
                  >
                    Copy
                  </button>
                </div>
                <div className="purchase-modal__instruction">
                  {`2. Send exactly ${costInAda} ADA to the address above`}
                  <br />
                  <span>
                    Any extra ADA sent will be treated as a donation. Thanks!
                  </span>
                  <br />
                  <span>
                    Do NOT send any ADA from an exchange. Use Daedalus, Eternl
                    or Nami.
                  </span>
                </div>
                <div className="purchase-modal__instruction">
                  3. Wait a couple of minutes and see what you got.
                  <br />
                  <Button
                    label="See your Doggos"
                    onButtonClick={() => navigate(
                      `/reciept/?address=${purchaseAddressData.address}`,
                    )}
                  />
                </div>
              </div>
              <div className="purchase-modal__footer">
                <div className="purchase-modal__footer-triangle" />
                <div className="purchase-modal__footer-content">
                  {expiresDate && (
                    <div className="purchase-modal__countdown">
                      <div className="purchase-modal__countdown-title">
                        Your hold expires in:
                      </div>
                      <Countdown daysInHours date={expiresDate} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {!purchaseAddressData && (
            <div className="purchase-modal__generating-address">
              <span>Generating purchase address...</span>
            </div>
          )}

          {purchaseAddressData?.error && (
            <div className="purchase-modal__error">
              <span>{purchaseAddressData.error}</span>
            </div>
          )}
        </div>
      </div>

      <Snackbar
        ContentProps={{}}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Address Copied to Clipboard"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        severity="success"
      />
    </Modal>
  );
};

PurchaseModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  costInAda: PropTypes.number.isRequired,
  costInLovelace: PropTypes.number.isRequired,
  selectedCount: PropTypes.number.isRequired,
  setModalIsOpen: PropTypes.func.isRequired,
};

export default PurchaseModal;
